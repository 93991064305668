// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import ahoy from 'ahoy.js'
window.ahoy = global.ahoy = ahoy;
import * as ActiveStorage from "@rails/activestorage"
import moment from "moment"
import "moment-timezone"
window.moment = moment;
import "channels"

Rails.start()
ActiveStorage.start()

window.Rails = global.Rails = Rails;

var jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("@nathanvda/cocoon")
require("trix")
require("@rails/actiontext")

// import "bootstrap"
import "@popperjs/core"
import "jquery-ui"
import "summernote"
import "select2"
import "perfect-scrollbar"
import "simplebar"

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
let popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
})
